<template>
  <textarea class="c-ta-scop" v-model="model" :disabled="disabled" @input="change"></textarea>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      default: false
    }
  },
  data() {
    return { model: "" };
  },
  methods: {
    change(e) {
      this.$emit("input", this.model);
      this.autoHeight();
    },
    autoHeight() {
      let ele = this.$el;
      if(!ele){
        return;
      }
      ele.style.height = 0;
      this.$nextTick(() => {
        ele.style.height = +ele.scrollHeight + 6 + "px";
      });
    }
  },
  mounted() {
    this.autoHeight();
  },
  watch: {
    value: {
      handler() {
        this.model = this.value;
        this.autoHeight();
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.c-ta-scop {
  resize: none;
  width: 100%;
}
</style>